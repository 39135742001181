import React, { Component } from "react"

import Button from "../../atom/button/Button"
import PraemienjackpotBanner from "./PraemienjackpotBanner"
import PraemienjackpotRanking from "./PraemienjackpotRanking"

import { AppContext } from "../../../data/ContextProvider";

import { getAppPrefs, getAppTexts } from "../../../data/api";

import { APP_JACKPOT } from "../../../data/constants"

import t from "../../../helpers/t9n";

export class Praemienjackpot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      jackpot: 0
    };
  }

  async componentDidMount() {
    const prefs = await getAppPrefs(APP_JACKPOT)
    const texts = await getAppTexts(APP_JACKPOT)

    this.setState({
      pageText: texts,
      jackpot: prefs,
      loading: false
    })
  }

  render() {
    if (this.state.loading) return t("global.loading");

    console.log("Praemienjackpot", this.state.jackpot);
    console.log("props", this.context);

    return (
      <>
        <div className="banner-section" id={this.props.id}>
          <div className={this.props.isHeaderOpen ? "banner-section-inner banner-section-inner--open" : "banner-section-inner"}>
            <PraemienjackpotBanner
              text={this.state.pageText}
              headline={this.state.jackpot.name}
              jackpot={this.state.jackpot.amount}
              appID={APP_JACKPOT}
            />

            { this.context?.project?.is_jackpot_rankingtable_active &&
              <PraemienjackpotRanking />
            }

          </div>

          {this.props.isHeaderOpen ? (
            <Button
              type="secondary"
              text={this.props.buttonOpen}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_JACKPOT)}
            />
          ) : (
            <Button
              type="secondary"
              text={this.props.buttonClose}
              textColor="black"
              backgroundColor="bg-white"
              icon="arrow"
              additionalClass={this.props.isHeaderOpen ? "apps-open-close-btn apps-open-close-btn--open" : "apps-open-close-btn"}
              onClick={() => this.props.toggleHeader(APP_JACKPOT)}
            />
          )}
        </div>
      </>
    );
  }
}

Praemienjackpot.contextType = AppContext;
export default Praemienjackpot;
