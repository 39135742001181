import React, { Component } from "react";
import { withRouter } from "react-router-dom"

import AppsNavigation from "../../components/apps/Global/AppsNavigation";
import HeaderSlider from "../../components/apps/Global/HeaderSlider";
import News from "../../components/apps/Global/News";
import WelcomeIntro from "../../components/apps/Global/WelcomeIntro";

import HerosPlace from "../../components/apps/Herosplace/HerosPlace";
import Neukundenjaeger from "../../components/apps/Neukundenjaeger/Neukundenjaeger";
import Praemiencasino from "../../components/apps/Praemiencasino/Praemiencasino";
import Praemienjackpot from "../../components/apps/Praemienjackpot/Praemienjackpot";
import SalesAttack from "../../components/apps/Salesattack/SalesAttack";
import SalesPush from "../../components/apps/Salespush/SalesPush";
import SchaffeIch from "../../components/apps/Schaffeich/SchaffeIch";
import TeamTrophy from "../../components/apps/Teamtrophy/TeamTrophy";
import SalesMarathon from "../../components/apps/Salesmarathon/SalesMarathon";
import SalesTriathlon from "../../components/apps/Salestriathlon/SalesTriathlon";


import Icon from "../../components/atom/icons/Icon";
import CardSlider from "../../components/module/card-slider/CardSlider";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import { getAssetSlider, getProjectTexts } from "../../data/api";
import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";
import { bodyForLang } from "../../lib/lib";

import {
  APP_SALESPUSH,
  APP_SALESATTACK,
  APP_TEAMTROPHY,
  APP_SCHAFFEICH,
  APP_NEUKUNDENJAEGER,
  APP_HEROSPLACE,
  APP_CASINO,
  APP_JACKPOT,
  APP_SALESMARATHON,
  APP_SALESTRIATHLON,
  HEADLINE_APPLICATION
} from "../../data/constants";
import { Chat } from "../../components/apps/Global/Chat";

export class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSlider: [],
      apps: [],
      appOpen: false,
      activeAppId: 0,
      loading: true,
      appHeadline: {
        body: "Ihre Applikationen",
      },
      navigationItems: [
        { enabled: true, weight: 0, title: "Ankerpunkte", id: "1" },
        { enabled: true, weight: 1, title: "News", id: "2" },
        { enabled: true, weight: 2, title: "Apps", id: "3" },
      ],
    };
  }

  async componentDidMount() {
    const headerSlider = await getAssetSlider("header");
    const appHeadline = await getProjectTexts(HEADLINE_APPLICATION);

    this.setState({
      headerSlider: headerSlider.slider,
      appHeadline: appHeadline ?? this.state.appHeadline,
      loading: false,
    });
  }

  toggleHeader = (id) => {
    const activeId = (this.state.activeAppId === id) ?  0 : id
    this.setState({
      activeAppId: activeId
    });
  }

  translateOpenLabel = (appName) => {
    if (window.currentLang === 'en') {
      return "open " + appName
    } else if (window.currentLang === 'fr') {
      return "ouvrir " + appName
    } else if (window.currentLang === 'it') {
      return "aprire " + appName
    } else {
      return appName + " öffnen"
    }
  }

  translateCloseLabel = (appName) => {
    if (window.currentLang === 'en') {
      return "close " + appName
    } else if (window.currentLang === 'fr') {
      return "fermer " + appName
    } else if (window.currentLang === 'it') {
      return "chiudere " + appName
    } else {
      return appName + " schließen"
    }
  }

  initInPageNavigation = (project, projectApps, headline) => {
    const items = []
    const localItems = this.state.navigationItems

    if (project.in_page_navigation && project.in_page_navigation.length > 0) {
      const in_page_navigation = JSON.parse(project.in_page_navigation)

      if (in_page_navigation) {

        in_page_navigation.forEach((item) => {

          const index = localItems.findIndex((element) => element.id === item.id)
          if (index !== -1) {
            localItems[index].enabled = item.enabled
            localItems[index].weight = item.weight
          }
        })
      }
    }

    localItems.sort((a, b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0))

    localItems.forEach(element => {

      if (element.enabled) {
        if (element.id === "1") {
          items.push(<AppsNavigation key={element.id} apps={projectApps} toggleHeader={this.toggleHeader} />)
        }
        if (element.id === "2") {
          items.push(<News key={element.id} />)
        }

        if (element.id === "3") {
          items.push(this.renderApps(headline, projectApps, element.id))
        }
      }

    })

    return items
  }

  renderApps = (headline, projectApps, key) => {
    if (projectApps.length) {
      return <div key={key} className="apps-header-list">
        <h2 className="apps-header-list__heading">{headline}</h2>

        {projectApps.map((a) => (
          <div id={a.component.id} key={a.component.id}>
            {a.component.app}
          </div>
        ))}
      </div>
    }
  }

  render() {
    if (this.state.loading) {
      return ""
    }

    const project = this.context.getProjectData();
    const headline = bodyForLang(this.state.appHeadline, window.currentLang);

    const apps = [
      {
        id: APP_JACKPOT,
        app: <Praemienjackpot buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_JACKPOT === this.state.activeAppId)} />,
        icon: <Icon icon="app-praemienjackpot" />
      },
      {
        id: APP_HEROSPLACE,
        app: <HerosPlace buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_HEROSPLACE === this.state.activeAppId)} />,
        icon: <Icon icon="app-herosplace" />
      },
      {
        id: APP_SALESPUSH,
        app: <SalesPush buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_SALESPUSH === this.state.activeAppId)}  />,
        icon: <Icon icon="app-salespush" />
      },
      {
        id: APP_SALESATTACK,
        app: <SalesAttack buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_SALESATTACK === this.state.activeAppId)} />,
        icon: <Icon icon="app-salesattack" />
      },
      {
        id: APP_NEUKUNDENJAEGER,
        app: <Neukundenjaeger buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_NEUKUNDENJAEGER === this.state.activeAppId)} />,
        icon: <Icon icon="app-neukundenjaeger" />
      },
      {
        id: APP_CASINO,
        app: <Praemiencasino buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_CASINO === this.state.activeAppId)} />,
        icon: <Icon icon="app-praemiencasino" />
      },
      {
        id: APP_SCHAFFEICH,
        app: <SchaffeIch buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_SCHAFFEICH === this.state.activeAppId)} />,
        icon: <Icon icon="app-schaffe-ich" />
      },
      {
        id: APP_TEAMTROPHY,
        app: <TeamTrophy buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_TEAMTROPHY === this.state.activeAppId)} />,
        icon: <Icon icon="app-teamtrophy" />
      },
      {
        id: APP_SALESMARATHON,
        app: <SalesMarathon buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_SALESMARATHON === this.state.activeAppId)} />,
        icon: <Icon icon="app-salespush" />
      },
      {
        id: APP_SALESTRIATHLON,
        app: <SalesTriathlon buttonOpen={this.translateCloseLabel("App")} buttonClose={this.translateOpenLabel("App")} toggleHeader={this.toggleHeader} isHeaderOpen={(APP_SALESTRIATHLON === this.state.activeAppId)} />,
        icon: <Icon icon="app-salestriathlon" />
      },
    ]

    const projectApps = []

    if (project && project.apps) {
      project.apps.forEach((app) => {
        apps.forEach((a) => {
          if (app.app_id === a.id ) {

            // zugang nicht eigeschraenkt
            if (app.team_id === 0) {
              projectApps.push({
                component: a,
                data: app
              })
            } else {
              //zugang nur fuer ein bestimmtes Team
              if (app.team_id === this.context.user.team_id) {
                projectApps.push({
                  component: a,
                  data: app
                })
              }
            }
          }
        })
      })
    }

    const inPageNavigation = this.initInPageNavigation(project, projectApps, headline)

    return (
      <>
        <div className="homepage-wrapper">
          {/* <ScrollToTop /> */}

          <HeaderSlider headerSlider={this.state.headerSlider} project={project}/>


          <div className="content-wrapper">
            <div className="content-wrapper-inner">

              <WelcomeIntro />

            </div>
          </div>

          {inPageNavigation}

          {project.is_shop_active &&
            <SectionRecommendations label={t("app.award.highlights")} />
          }

          {project.is_shop_active && this.context.project.shop_highlights_enabled &&
            <div className="section__card-slider">
              <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
            </div>
          }

          {/* Chat */}
          {this.context.project.user_chat_enabled ? (
            <Chat />
          ) : null}

        </div>
      </>
    );
  }
}

Homepage.contextType = AppContext;
export default withRouter(Homepage);
