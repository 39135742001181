import React, { Component } from "react";
import t from "../../../helpers/t9n";

import { getJackpotRankings } from "../../../data/api";

export class PraemienjackpotRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const rankings = await getJackpotRankings();

    console.log("rankings", rankings);

    this.setState({
      team_rankings: rankings.team_rankings,
      user_rankings: rankings.user_rankings,
      labels: rankings.labels,
      loading: false
    })
  }


  renderNewPerformances() {
    return (
      <>
        <div className="heros-ranking-new-state">
          <div className="heros-ranking__board">
            <div className="heros-ranking__board--wrapper" >
              <div className="heros-ranking__top-bar">
                <div className="heros-ranking__item">
                  <span>Rang</span>
                </div>
                <div className="heros-ranking__item">
                  <span>Name</span>
                </div>
                <div className="heros-ranking__item">
                  <span>Vorname</span>
                </div>
                { this.state.labels && this.state.labels.map((label, index) => {
                  return <div className="heros-ranking__item" key={index}>
                    <span>{label}</span>
                  </div>
                })}

              </div>
              { this.state.user_rankings &&
                <div className={`heros-ranking__row heros-ranking__row-active`} key={0}>

                    <div className="heros-ranking__item">
                      <span>{this.state.user_rankings?.rank}</span>
                    </div>

                    <div className="heros-ranking__item">
                      <span>{this.state.user_rankings?.first_name}</span>
                    </div>

                    <div className="heros-ranking__item">
                      <span>{this.state.user_rankings?.last_name}</span>
                    </div>

                    <div className="heros-ranking__item">
                      <span>{Number(this.state.user_rankings?.value_1 ).toLocaleString()}</span>
                    </div>
                    { this.state.user_rankings?.value_2 &&
                      <div className="heros-ranking__item">
                        <span>{Number(this.state.user_rankings?.value_2 ).toLocaleString()}</span>
                      </div>
                    }
                    { this.state.user_rankings?.value_3 &&
                      <div className="heros-ranking__item">
                        <span>{Number(this.state.user_rankings?.value_3 ).toLocaleString()}</span>
                      </div>
                    }
                  </div>
                }
                {this.renderPerformanceTable()}
            </div>
          </div>
        </div>
      </>
    )
  }

  renderPerformanceTable() {
    const out = []

    this.state.team_rankings.map((ranking, item_index) => {
      out.push(
        <div className='heros-ranking__row heros-ranking__row' key={item_index}>
          <div className="heros-ranking__item">
            <span>{ranking?.rank} </span>
          </div>

          <div className="heros-ranking__item">
            <span>{ranking?.first_name}</span>
          </div>

          <div className="heros-ranking__item">
            <span>{ranking?.last_name}</span>
          </div>

          <div className="heros-ranking__item">
            <span>{Number( ranking?.value_1 ).toLocaleString()}</span>
          </div>
          { ranking?.value_2 &&
            <div className="heros-ranking__item">
              <span>{Number(ranking?.value_2 ).toLocaleString()}</span>
            </div>
          }
          { ranking?.value_3 &&
            <div className="heros-ranking__item">
              <span>{Number(ranking?.value_3 ).toLocaleString()}</span>
            </div>
          }
        </div>
      );
    })
    return out;
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <div className="heros-ranking__section heros-ranking__section--open">
        <span className="heros-ranking__header">
          <h2 className="heros-ranking__label">
            Ranking
          </h2>
        </span>
        <div className="heros-ranking__content">
          <div className="heros-ranking__wrapper">
            { this.renderNewPerformances() }
          </div>
        </div>
      </div>
    );
  }
}

export default PraemienjackpotRanking;
